import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchNotifications,
  updatePreference,
} from "patient_app/api/profileActions";
import {
  NEW_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import ProfileField from "patient_app/views/profiles/partials/ProfileField";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import MainTabBack from "patient_app/views/profiles/partials/MainTabBack";

import assets from "patient_app/components/chat/assets";
import { mobileCheck } from "patient_app/helpers/supported";
import { getUrlVars } from "patient_app/helpers/linkHelpers";
import permissions from "patient_app/helpers/permissions";

import notificationStyles from "patient_app/stylesheets/profiles/notificationStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNotifications: (profileId, feedback) =>
      dispatch(fetchNotifications(profileId)),
    updatePreference: (profileId, preference) =>
      dispatch(updatePreference(profileId, preference)),
    newFieldSpecificError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
  };
};

const mapStateToProps = (state) => {
  return {
    primaryDiagnosis: state.common.primaryDiagnosis,
    preference: state.profile.preference,
    loading: state.profile.loading,
    success: state.profile.success,
    user: state.common.user,
  };
};

class Notifications extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {
      preferenceChanges: null,
    };
  }

  componentDidMount() {
    document.title = "Notifications | Workit Health";
    this.props.fetchNotifications(this.profileId);
    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);

    if (this.props.preference) {
      this.setState({ preferenceChanges: this.props.preference });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.preference && this.props.preference) {
      // when preference is fetched
      this.setState({ preferenceChanges: this.props.preference });
    }

    if (
      !prevProps.success &&
      this.props.success &&
      this.state.preferenceChanges
    ) {
      // when preference is successfully updated
      this.setState({ preferenceChanges: this.props.preference });
    }
  }

  render() {
    let { loading, preference, user } = this.props;
    let { preferenceChanges } = this.state;

    return (
      <div>
        <MainTabBack />

        <div className="profile-section last">
          <h1>Notifications</h1>

          {!preferenceChanges && <LoadingSpinner />}

          {preferenceChanges && (
            <div>
              <div className="profile-list preference">
                <div className="list-item header">
                  <div className="row">
                    <p>Notification</p>
                    <div className="col align-center">
                      <p>Enabled</p>
                    </div>
                  </div>
                </div>

                {this.renderPreferences()}
              </div>

              <button
                className={`custom-button submit half ${
                  loading ? "loading" : ""
                }`}
                onClick={this.handleSubmit}
              >
                Save Changes
              </button>

              <p className="under-submit">
                If you are not receiving text notifications and you've replied
                STOP in a text conversation, you will need to reply START in
                that conversation to begin receiving texts again.
              </p>
            </div>
          )}
        </div>

        <style jsx>{notificationStyles}</style>
      </div>
    );
  }

  getPreferencesByTier(user) {
    const { primaryDiagnosis } = this.props;
    let allPreferences = [];
    allPreferences.push(["Membership Reminders", "membership_reminders"]);
    allPreferences.push(["Program and App Updates", "program_updates"]);
    allPreferences.push(["Stories and News", "stories_and_news"]);
    allPreferences.push(["Community and Events", "community_and_events"]);
    allPreferences.push(["Member Research", "member_research"]);
    allPreferences.push(["Daily Affirmations SMS", "daily_affirmations"]);

    return allPreferences;
  }

  renderPreferences() {
    let { user } = this.props;
    let { preferenceChanges } = this.state;
    let allPreferences = this.getPreferencesByTier(user);

    const hasNoEmail = ["daily_reminders"];
    const hasNoText = ["daily_reminders"];
    return allPreferences.map((pref, i) => {
      const field = pref[1];
      const userPref = preferenceChanges[field];

      return (
        <div className="list-item" key={i}>
          <div className="row">
            <p>{pref[0]}</p>
            <div className="col align-center">
              <input
                type="checkbox"
                checked={userPref ? true : false}
                onChange={() => this.handleUpdateField(field)}
                aria-label={`Notification for ${pref[0]}`}
              />
            </div>
          </div>
          <style jsx>{notificationStyles}</style>
        </div>
      );
    });
  }

  handleUpdateField = (field) => {
    let pref = this.state.preferenceChanges;
    pref[field] = !pref[field];
    this.setState({ preferenceChanges: pref });
  };

  handleSubmit = () => {
    this.props.updatePreference(this.profileId, this.state.preferenceChanges);
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Notifications)
);
