import React from "react";
import cn from "classnames";
import commonAssets from "patient_app/assets";

import Dropdown from "patient_app/components/navbar/partials/Dropdown";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUser, fetchProfile } from "patient_app/api/commonActions";
import { getUrlVars } from "patient_app/helpers/linkHelpers";
import permissions from "patient_app/helpers/permissions";
import { OPEN_CHAT } from "patient_app/constants/actionTypes";

import navbarStyles from "patient_app/stylesheets/components/navbarStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(fetchUser()),
    fetchProfile: () => dispatch(fetchProfile()),
    openChat: (data) => dispatch({ type: OPEN_CHAT, data: data }),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.common.user,
    showChat: state.chat.showChat,
    unreadCount: state.chat.unreadCount,
  };
};

class Nav extends React.Component {
  userIdFromUrl = getUrlVars(window.location.href)["user_id"];

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    !this.props.user && this.props.fetchUser();
    !this.props.profile && this.props.fetchProfile();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ expanded: false });
    }
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    let { user, showChat, unreadCount } = this.props;

    let { expanded } = this.state;

    let homepageLink = permissions.isClinic(user)
      ? "/appointments"
      : "/courses";
    if (!!this.userIdFromUrl) {
      homepageLink += `?user_id=${this.userIdFromUrl}`;
    }

    return (
      <nav
        className="patient"
        ref={this.setWrapperRef}
        role="navigation"
        aria-label="Main navigation"
      >
        <a href="#App-Body-Content" id="skip-to-content">
          Skip to Main Content
        </a>

        <div className="main-links-container">
          <Link
            to={homepageLink}
            className="logo-svg"
            aria-label="Workit Health logo, go to app homepage."
          >
            <img src={commonAssets.greenLogoNoText} alt="" />
          </Link>

          {user && this.renderMainLinks(user)}
        </div>

        <div className="toggle-links">
          {user && showChat && (
            <div className="chat">
              <button
                className="unread-messages custom-button"
                onClick={() => this.props.openChat({ view: "index" })}
              >
                <img src={commonAssets.chatBub} alt="chat bubble" />
                <span className="badge" id="nav-unread-count-data">
                  {unreadCount > 0 && unreadCount}
                </span>
              </button>
            </div>
          )}

          <Dropdown
            expanded={expanded}
            onToggleDropdown={this.toggleDropdown}
            disabled={!!this.userIdFromUrl}
          />
        </div>

        <style jsx>{navbarStyles}</style>
      </nav>
    );
  }

  toggleDropdown = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  renderMainLinks(user) {
    return (
      <div style={{ display: "flex" }}>
        <div
          className={cn(
            "link",
            window.location.href.includes("appointments") && "selected"
          )}
        >
          <Link
            to={`/appointments${
              !!this.userIdFromUrl ? `?user_id=${this.userIdFromUrl}` : ""
            }`}
            className="first_focus"
          >
            Dashboard
          </Link>
        </div>

        <div
          className={cn(
            "link",
            window.location.href.includes("courses") && "selected"
          )}
        >
          <Link
            to={`/courses${
              !!this.userIdFromUrl ? `?user_id=${this.userIdFromUrl}` : ""
            }`}
            className="first_focus"
          >
            Courses
          </Link>
        </div>

        <div
          className={cn(
            "link",
            (window.location.href.includes("groups") ||
              window.location.href.includes("community")) &&
              "selected"
          )}
        >
          <Link
            to={`/groups${
              !!this.userIdFromUrl ? `?user_id=${this.userIdFromUrl}` : ""
            }`}
            className="first_focus"
          >
            Group
          </Link>
        </div>

        {["aud_abstinence", "aud_moderation"].includes(user.treatment_type) && (
          <div
            className={cn(
              "link",
              window.location.href.includes("forum") && "selected"
            )}
          >
            <Link
              to={`/member_forum${
                !!this.userIdFromUrl ? `?user_id=${this.userIdFromUrl}` : ""
              }`}
              className="first_focus"
            >
              Forum
            </Link>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
