import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from "react-redux";

import { fetchUser, fetchProfile } from "patient_app/api/commonActions";
import { fetchCurriculum } from "patient_app/api/curriculumActions";
import {
  checkIfQuestionnaireDue,
  checkIfPendingItem,
} from "patient_app/api/sessionActions";
import { CURRICULUM_LOAD, NEW_ERRORS } from "patient_app/constants/actionTypes";

import Nav from "patient_app/components/navbar/Nav";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import PatientChat from "patient_app/components/chat/PatientChat";

import DashboardBanner from "patient_app/components/utils/DashboardBanner";
import ProgressCircle from "patient_app/views/curriculums/partials/ProgressCircle";
import Booking from "patient_app/views/curriculums/partials/Booking";
import SpecialDelivery from "patient_app/views/curriculums/partials/SpecialDelivery";
import NetPromoterScore from "patient_app/components/utils/NetPromoterScore";

import { getUrlVars } from "components/utils/helpers/linkHelpers";
import permissions from "patient_app/helpers/permissions";
import assets from "patient_app/assets";
import dashboardBlockStyles from "patient_app/stylesheets/appointments/dashboardBlockStyles";
import courseStyles from "patient_app/stylesheets/curriculums/courseStyles";
import bookingStyles from "patient_app/stylesheets/curriculums/bookingStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    checkIfQuestionnaireDue: () => dispatch(checkIfQuestionnaireDue()),
    checkIfPendingItem: () => dispatch(checkIfPendingItem()),
    fetchUser: () => dispatch(fetchUser()),
    fetchProfile: (user) => dispatch(fetchProfile(user)),
    onErrors: (errors) => dispatch({ type: NEW_ERRORS, errors: errors }),
    nullCurriculum: () => dispatch({ type: CURRICULUM_LOAD, curriculum: null }),
    fetchCurriculum: (curriculumId, userId) =>
      dispatch(fetchCurriculum(curriculumId, userId)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.common.user,
    paywall: state.common.paywall,
    profile: state.common.profile,
    coach: state.common.counselor,
    chats: state.chat.chats,
    errors: state.common.errors,
    success: state.common.success,
    isFetching: state.common.isFetching,
    curriculum: state.curriculum.curriculum,
    sections: state.curriculum.sections,
    skipLoading: state.curriculum.skipLoading,
    selectedItem: state.curriculum.selectedItem,
    gutCheckDue: state.session.gutCheckDue,
    alcoholCheckDue: state.session.alcoholCheckDue,
    npsDue: state.session.npsDue,
    hasPendingItem: state.session.hasPendingItem,
    path: state.session.path,
  };
};

class Show extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "my_courses", // can be "my_courses", "special_delivery"
    };
  }
  componentDidMount = () => {
    document.title = "Courses | Workit Health";

    let { user, profile, coach, curriculum, gutCheckDue } = this.props;
    if ([null, undefined].includes(gutCheckDue)) {
      this.props.checkIfQuestionnaireDue();
    }
    this.props.checkIfPendingItem();

    if (!user) {
      this.props.fetchUser();
    } else if (user && user.tier === 0) {
      this.props.history.push("/onboarding/freemium_block");
    } else if (user && user.locked_out) {
      this.props.history.push("/onboarding/program_description");
    }

    !profile && this.props.fetchProfile();

    if (!curriculum) {
      const curricId = this.props.match.params.id;
      const userId = getUrlVars(window.location.href)["user_id"];
      this.props.fetchCurriculum(curricId, userId);
    }

    const view = getUrlVars(window.location.href)["view"];
    if (["my_courses", "special_delivery"].includes(view)) {
      this.setState({ view: view });
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (!prevProps.user && this.props.user && this.props.user.locked_out) {
      console.log("we redirectin");
      this.props.history.push("/onboarding/program_description");
    }

    if (!prevProps.gutCheckDue && this.props.gutCheckDue) {
      window.location.href = "/gut_checks/new";
    }

    if (!prevProps.alcoholCheckDue && this.props.alcoholCheckDue) {
      window.location.href = "/alcohol_checks/new";
    }

    if (
      !prevProps.hasPendingItem &&
      this.props.hasPendingItem &&
      this.props.hasPendingItem === true
    ) {
      console.log("redirecting as pending item", this.props.path);
      window.location.href = this.props.path;
    }

    if (
      this.props.profile &&
      this.props.paywall &&
      this.props.paywall === true
    ) {
      this.props.history.push(
        `/profiles/${this.props.profile.id}/payment?status=failed&tab=payment_details`
      );
      this.props.onErrors([
        { text: "Please update your payment information to continue." },
      ]);
      return;
    }

    if (prevProps.match.url !== this.props.match.url) {
      // when /curriculums/:id changes to /courses, re-fetch active curriculum
      this.props.nullCurriculum();
      const curricId = this.props.match.params.id;
      this.props.fetchCurriculum(curricId);
    }
  };

  render() {
    let {
      user,
      errors,
      success,
      curriculum,
      npsDue,
      sections,
      hasPendingItem,
    } = this.props;

    let { view } = this.state;

    let contentLoaded = user && curriculum && hasPendingItem === false;
    let showSidebar = false;
    if (user && !permissions.isClinic(user)) {
      showSidebar = true;
    }

    let title = "Courses";
    if (curriculum && this.props.match.path === "/curriculums/:id") {
      title = `Courses: ${curriculum.name}`;
    }

    return (
      <div className="Patient-App-Container">
        {user && (
          <div>
            <Nav />

            {npsDue && <NetPromoterScore />}

            <div
              id="App-Body-Content"
              className={cn("App-Courses-Show", !contentLoaded && "loading")}
              role="main"
            >
              <PatientChat
                coachName={"Counselor"}
                userIsClinic={permissions.isClinic(user)}
                cta={permissions.isCore(user)}
                chatSessionId={
                  getUrlVars(window.location.href)["chat_session_id"]
                }
                color="#1A7A65"
              />

              {!contentLoaded ? (
                <LoadingSpinner />
              ) : (
                <div
                  className={cn(
                    "Courses-Container",
                    showSidebar && "showing-sidebar"
                  )}
                >
                  <div className="Tabs-Courses">
                    <div className="dashboard-block">
                      <div className="header">
                        <h1>{title}</h1>

                        <div className="course-toggle">
                          <button
                            className={`custom-button ${
                              view === "my_courses" ? "selected" : ""
                            }`}
                            onClick={() => this.handleSelect("my_courses")}
                          >
                            My Courses
                          </button>

                          <button
                            className={`custom-button ${
                              view === "special_delivery" ? "selected" : ""
                            }`}
                            onClick={() =>
                              this.handleSelect("special_delivery")
                            }
                          >
                            Special Delivery
                          </button>
                        </div>
                      </div>

                      {view === "my_courses" && this.renderSectionsList()}
                      {view === "special_delivery" && <SpecialDelivery />}
                    </div>
                  </div>

                  {showSidebar && (
                    <div className="Courses-Sidebar">
                      <div className="Sidebar-Tile community">
                        <div className="header">
                          <p className="title">Group</p>
                        </div>

                        <a
                          href="https://www.workithealth.com/community/"
                          target="_blank"
                          className="block-item"
                        >
                          <div className="details">
                            <p>Upcoming Events</p>
                          </div>

                          <img src={assets.chevronRightNavy} />
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        <style jsx>{dashboardBlockStyles}</style>
        <style jsx>{courseStyles}</style>
        <style jsx>{bookingStyles}</style>
      </div>
    );
  }

  renderSectionsList() {
    let { curriculum } = this.props;

    const curriculumId =
      this.props.match.path === "/courses" ? "default" : curriculum.id;
    const userId = getUrlVars(window.location.href)["user_id"];
    return curriculum.sections.map((section, i) => {
      let url = `/curriculums/${curriculumId}/section?section_id=${section.id}`;
      if (userId) {
        url += `&user_id=${userId}`;
      }
      return (
        <div className="block-item" key={i}>
          <Link to={url} className="section-link">
            <div className="details">
              <p className="title curriculum">{section.title}</p>
              {section.next_item && (
                <p className="next-item-title">
                  Next: {section.next_item.title}
                </p>
              )}
            </div>

            {section.next_item && (
              <p className="note-text">Next: {section.next_item.title}</p>
            )}

            <ProgressCircle
              size={36}
              strokeWidth={3}
              percent={section.percent}
            />
          </Link>

          <style jsx>{dashboardBlockStyles}</style>
          <style jsx>{`
            .block-item :global(a.section-link) {
              display: flex;
              align-items: center;
              width: 100%;
            }
            .block-item:last-of-type {
              border-bottom: none;
            }
          `}</style>
        </div>
      );
    });
  }

  handleSelect = (view) => {
    this.setState({ view: view });
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Show);
