import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { DateTime } from "luxon";

import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import { submitRating } from "patient_app/api/videoCallActions";
import { OPEN_CHAT, NEW_ERRORS } from "patient_app/constants/actionTypes";

import MultiSelectField from "patient_app/views/gtc/MultiSelectField";
import ProfileField from "patient_app/views/profiles/partials/ProfileField";

import dates from "patient_app/helpers/dates";
import textHelpers from "patient_app/helpers/textHelpers";
import commonAssets from "patient_app/assets";
import busyScreenStyles from "patient_app/stylesheets/video_rooms/busyScreenStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    submitRating: (videoCallId, params) =>
      dispatch(submitRating(videoCallId, params)),
    openChat: (data) => dispatch({ type: OPEN_CHAT, data: data }),
    generalErrors: (errors) => dispatch({ type: NEW_ERRORS, errors: errors }),
  };
};

const mapStateToProps = (state) => {
  return {
    adminType: state.videoCall.adminType,
    clinic: state.common.clinic,
    coach: state.common.counselor,
    profile: state.common.profile,
    queuePosition: state.videoCall.queuePosition,
    room: state.videoCall.room,
    roomStatus: state.videoCall.roomStatus,
    user: state.common.user,
    videoCall: state.videoCall.videoCall,
    zoomEventParticipant: state.videoCall.zoomEventParticipant,
  };
};

class BusyScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      issues: [],
      details: "",
      rating: -1,
      waitStart: null,
      noQueuePosition: false,
      memberClicked: false,
    };
  }

  componentDidMount() {
    this.setState({ waitStart: DateTime.local() });
    // check for queuePosition every 10 seconds
    this.interval = setInterval(this.checkQueuePosition, 10000);
  }

  checkQueuePosition = () => {
    if (![undefined, null].includes(this.props.queuePosition)) {
      // queue position exists, don't need this anymore
      clearInterval(this.interval);
    }

    if (DateTime.local().minus({ seconds: 20 }) > this.state.waitStart) {
      // queue position has not loaded after 30 seconds, something may have gone wrong
      this.setState({ noQueuePosition: true });
      clearInterval(this.interval);
    }
  };

  render() {
    let {
      adminType,
      clinic,
      queuePosition,
      roomStatus,
      videoCall,
      zoomEventParticipant,
      shouldUseZoom,
    } = this.props;

    let { issues, details, rating, waitStart, noQueuePosition, memberClicked } =
      this.state;

    let statusText = "Please wait...";
    let statusSubtitle =
      "We are currently with other members, but we'll be with you shortly.";
    let roomClosed = false;

    if (roomStatus === "WAITING") {
      if (![undefined, null].includes(queuePosition)) {
        if (queuePosition === 0) {
          statusText = "You are next in line.";
        } else {
          statusText = `You are ${textHelpers.stringifyNumber(
            queuePosition + 1
          )} in line.`;
        }
      } else if (noQueuePosition) {
        statusText = "Unable to join the queue, please refresh the page.";
      }

      if (clinic && window.location.href.includes(clinic.drug_test_url)) {
        let duringTestingTime = false;
        const dayOfWeek = DateTime.local().toFormat("cccc");
        clinic.testing_times.forEach((time) => {
          // don't need to check if it's not the same day of the week
          if (dayOfWeek === time.day) {
            let startAt = DateTime.local()
              .setZone(time.time_zone)
              .set({ hour: time.sa_h, minute: time.sa_m, second: 0 });
            let endAt = DateTime.local()
              .setZone(time.time_zone)
              .set({ hour: time.ea_h, minute: time.ea_m, second: 0 });
            if (DateTime.local() >= startAt && DateTime.local() <= endAt) {
              duringTestingTime = true;
            }
          }
        });

        // after checking regular testing hours, check if it's a holiday
        if (clinic.holidays) {
          if (dates.todayIsHoliday(clinic.holidays)) {
            duringTestingTime = false;
          }
        }

        if (!duringTestingTime) {
          statusText = "";
          statusSubtitle =
            "Workit drug tests on a reoccuring basis during your clinic's scheduled testing hours. If you need to test outside of this window please contact your counselor.";
          roomClosed = true;
        }
      }
    }

    if (roomStatus === "CONNECTING" || roomStatus === "CONNECTING") {
      statusText = "Workit Health is Connecting";
      statusSubtitle = "Please wait for the video to connect.";
    }

    if (roomStatus === "DISCONNECTED") {
      statusText = "Call Ended";
      statusSubtitle = null;
    }

    const feedbackSubmitted =
      videoCall && (!!videoCall.issues.length || !!videoCall.details);
    const showFeedback = !feedbackSubmitted && rating > 0 && rating <= 3;
    const feedbackOptions = [
      "Internet Issues",
      "Video Quality",
      "Audio Quality",
      `${
        ["Provider", "Counselor"].includes(adminType)
          ? `My ${adminType}`
          : "Staff"
      } was absent/late`,
      `${
        ["Provider", "Counselor"].includes(adminType)
          ? `My ${adminType}`
          : "Staff"
      } was not helpful`,
      "Other",
    ];

    return (
      <div
        className={cn(
          "Video-Busy-Screen",
          roomStatus,
          showFeedback ? "show-feedback" : ""
        )}
      >
        {/* DISCONNECTED styling is different than the rest right now */}
        {roomStatus !== "DISCONNECTED" && (
          <div className={`inner ${roomStatus}`}>
            {roomStatus !== "DISCONNECTED" &&
              !roomClosed &&
              shouldUseZoom &&
              !memberClicked && (
                <>
                  {zoomEventParticipant?.meeting_url ? (
                    <div>
                      <h1>Your Care Team is ready to see you now.</h1>
                      <p className="main-subtitle">
                        Go to your Zoom room to meet with your care team and
                        complete your drug screen.
                      </p>

                      <a
                        className="custom-button button submit green"
                        target="_blank"
                        href={zoomEventParticipant.meeting_url}
                        onClick={this.handleJoinZoom}
                      >
                        Go to Zoom
                      </a>

                      <a
                        className="green link"
                        target="_blank"
                        href={this.getJoinFromBrowserLink(zoomEventParticipant)}
                        onClick={this.handleJoinZoom}
                      >
                        join from browser
                      </a>
                    </div>
                  ) : (
                    <div>
                      <h1>
                        You're in line. Hang tight, and your Care Team will be
                        with you shortly.
                      </h1>
                      <p className="main-subtitle">
                        Once a member of your Care Team is available, you will
                        be re-directed to meet them in a Zoom room to conduct
                        your drug screen.
                      </p>
                    </div>
                  )}
                </>
              )}
            {roomStatus !== "DISCONNECTED" && !roomClosed && !shouldUseZoom && (
              <div>
                <h1>Welcome! Please take a (virtual) seat.</h1>
                <p className="main-subtitle">
                  This is our virtual waiting room, and just like a normal
                  Doctor's office, we might be running early or behind.
                </p>
              </div>
            )}

            {roomStatus !== "DISCONNECTED" &&
              !roomClosed &&
              shouldUseZoom &&
              memberClicked && (
                <div>
                  <h1>Drug test in progress...</h1>
                  <p className="main-subtitle">
                    If you have already completed your drug test, please click
                    the &quot;Dashboard&quot; link above.
                  </p>
                </div>
              )}

            {roomClosed && <h1>Your drug testing window is now closed.</h1>}

            {!shouldUseZoom && (
              <>
                <div className="circle">
                  <div className={`status-radius ${roomStatus}`}>
                    <div
                      className={`status hide-on-connected ${roomStatus}`}
                    ></div>
                  </div>
                  <div className="logo">
                    <img
                      src={commonAssets.greenLogoNoText}
                      alt="Workit Health logo."
                    />
                  </div>
                </div>

                <div className="status">
                  <p className={cn("status-text", roomStatus)}>{statusText}</p>
                  <p className="status-subtitle">{statusSubtitle}</p>
                </div>
              </>
            )}
          </div>
        )}

        {roomStatus === "DISCONNECTED" && (
          <div className="info-box">
            <div className="header">
              <p>{statusText}</p>
            </div>

            {feedbackSubmitted && (
              <div className="rating-section">
                <p className="question">Feedback submitted, thank you!</p>
              </div>
            )}

            {!feedbackSubmitted && (
              <div className="rating-section">
                <p className="question">How did your call go?</p>
                <div className="rating-star">{this.renderStars()}</div>

                {showFeedback && (
                  <div className="rating-feedback">
                    <MultiSelectField
                      field="video-feedback-select"
                      fieldType="select"
                      onUpdateField={(list) => this.setState({ issues: list })}
                      options={feedbackOptions}
                      showLabel={true}
                      title="Let us know what went wrong:"
                      value={issues}
                    />

                    <ProfileField
                      field="video-feedback-text"
                      fieldType="textarea"
                      onUpdateField={(value, field) =>
                        this.setState({ details: value })
                      }
                      showLabel={true}
                      title="Please provide more information:"
                      value={details}
                    />
                  </div>
                )}

                {showFeedback && (
                  <button
                    className="custom-button submit green"
                    onClick={this.handleSubmitFeedback}
                  >
                    Submit Feedback
                  </button>
                )}

                <button
                  className="custom-button submit"
                  onClick={() => window.location.reload()}
                >
                  Rejoin
                </button>
              </div>
            )}
          </div>
        )}

        <style jsx>{busyScreenStyles}</style>
      </div>
    );
  }

  renderStars() {
    let { rating } = this.state;
    return [1, 2, 3, 4, 5].map((num) => {
      return (
        <button
          key={num}
          className="custom-button star"
          onClick={() => this.handleSelectStar(num)}
          aria-label={`Rate call ${num} out of 5 stars`}
        >
          {num <= rating ? (
            <img src={commonAssets.starYellow} alt="Yellow star" />
          ) : (
            <img src={commonAssets.starGray} alt="Gray star" />
          )}
          <style jsx>{busyScreenStyles}</style>
        </button>
      );
    });
  }

  getJoinFromBrowserLink = (participant) => {
    let host = "https://www.workit.health";
    if (window.location.hostname === "staging.workithealth.com") {
      host = "https://d5fm6m12vg0ji.cloudfront.net";
    } else if (window.location.hostname === "localhost") {
      host = "http://localhost:3001";
    }

    const joinLink = `${host}/?zoom_registrant_id=${participant.registrant_id}&zoom_registrant_token=${participant.zoom_registrant_token}`;

    return joinLink;
  };

  handleSelectStar = (num) => {
    const params = { rating: num };
    this.setState(params);
    if (num > 3) {
      // clear details in state, if they exist
      this.setState({ issues: [], details: "" });
    }
    this.props.videoCall &&
      this.props.submitRating(this.props.videoCall.id, params);
  };

  handleSubmitFeedback = () => {
    let { videoCall } = this.props;
    let { issues, details, rating } = this.state;

    this.props.generalErrors([]); // clear errors
    if (!videoCall) {
      return;
    }

    if (!issues.length && !details.length) {
      this.props.generalErrors([
        { text: "Please provide details of what went wrong." },
      ]);
      return;
    }

    const params = {
      rating: rating,
      issues: issues,
      details: details,
    };
    this.props.submitRating(this.props.videoCall.id, params);
  };

  handleJoinZoom = () => {
    this.setState({ memberClicked: true });
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BusyScreen);
