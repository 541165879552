import PropTypes from "prop-types";
import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchAccount } from "patient_app/api/profileActions";
import {
  PROFILE_UPDATE_STATE_LOCAL,
  NEW_ERRORS,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import MainTabBack from "patient_app/views/profiles/partials/MainTabBack";
import AccountInfo from "patient_app/views/profiles/partials/account/AccountInfo";
import Password from "patient_app/views/profiles/partials/account/Password";
import TwoFactor from "patient_app/views/profiles/partials/account/TwoFactor";
import EmergencyContact from "patient_app/views/profiles/partials/account/EmergencyContact";
import Email from "patient_app/views/profiles/partials/account/Email";
import Phone from "patient_app/views/profiles/partials/account/Phone";

import { getUrlVars } from "patient_app/helpers/linkHelpers";
import { mobileCheck } from "patient_app/helpers/supported";
import layout from "patient_app/stylesheets/profiles/layout";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAccount: (profileId, params) =>
      dispatch(fetchAccount(profileId, params)),
    setProfileState: (obj, value) =>
      dispatch({ type: PROFILE_UPDATE_STATE_LOCAL, obj: obj, value: value }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
  };
};

const mapStateToProps = (state) => {
  return {
    mainTab: state.profile.mainTab,
    subTab: state.profile.subTab,
  };
};

class Account extends React.Component {
  profileId = this.props.match.params.id;

  componentDidMount = async () => {
    document.title = "Profile | Workit Health";

    const accountTabs = [
      "profile",
      "email",
      "phone",
      "password",
      "two_factor",
      "emergency_contact",
    ];
    if (!mobileCheck()) {
      this.props.fetchAccount(this.profileId, ["profile", "emergency_contact"]);
      this.props.setProfileState("subTab", accountTabs);
    } else {
      const tab = getUrlVars(window.location.href)["tab"];
      if (tab && accountTabs.includes(tab)) {
        this.props.setProfileState("subTab", [tab]);
      }
    }

    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);
  };

  render() {
    let { mainTab, subTab } = this.props;

    return (
      <div>
        {mainTab.length > 0 && subTab.length === 0 && (
          <nav
            className="profile-sidebar"
            role="navigation"
            aria-label="Profile navigation"
          >
            <MainTabBack />

            <button
              className="custom-button tab"
              onClick={() => this.props.setProfileState("subTab", ["profile"])}
            >
              Account Info
            </button>

            <button
              className="custom-button tab"
              onClick={() => this.props.setProfileState("subTab", ["email"])}
            >
              Email
            </button>

            <button
              className="custom-button tab"
              onClick={() => this.props.setProfileState("subTab", ["phone"])}
            >
              Phone
            </button>

            <button
              className="custom-button tab"
              onClick={() => this.props.setProfileState("subTab", ["password"])}
            >
              Password
            </button>

            <button
              className="custom-button tab"
              onClick={() =>
                this.props.setProfileState("subTab", ["two_factor"])
              }
            >
              Two-Factor Authentication
            </button>

            <button
              className="custom-button tab"
              onClick={() =>
                this.props.setProfileState("subTab", ["emergency_contact"])
              }
            >
              Emergency Contacts
            </button>
          </nav>
        )}

        {subTab.includes("profile") && <AccountInfo />}
        {subTab.includes("email") && <Email />}
        {subTab.includes("phone") && <Phone />}
        {subTab.includes("password") && <Password />}
        {subTab.includes("two_factor") && <TwoFactor />}
        {subTab.includes("emergency_contact") && <EmergencyContact />}

        <style jsx>{layout}</style>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Account)
);
