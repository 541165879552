import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables.js";

export default css.global`
  body.forum,
  body.community {
    background-color: white;
  }

  .Community-Container {
    padding-bottom: 2rem;
  }

  .Tabs-Community {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .Tabs-Community .inner-cont,
  .Tabs-Forum .inner-cont {
    max-width: 800px;
  }

  .Tabs-Community .header {
    position: relative;
    box-sizing: border-box;
  }

  .Tabs-Community .header h2 {
    margin: 0;
    font-size: 1.2rem;
    font-family: ${variables.objBold};
    color: ${variables.darkBlue};
    letter-spacing: 0.25px;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .Tabs-Community .header h1 {
    margin: 0;
    font-size: 1.6rem;
    font-family: ${variables.objBold};
    color: ${variables.darkBlue};
    letter-spacing: 0.25px;
    line-height: 1.5em;
    margin-bottom: 2rem;
  }

  p {
    font-family: ${variables.objReg};
    font-size: 0.9em;
    color: #283e4e;
    margin-bottom: 2rem;
    line-height: 25px;
  }

  a {
    color: ${variables.green};
    font-family: ${variables.objBold};
    text-decoration: underline;
  }

  @media only screen and (max-width: 900px) {
    .Tabs-Community {
      max-width: 80%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media only screen and (max-width: 768px) {
    .Tabs-Community {
      max-width: 100%;
      margin: 0;
      padding-left: 20px;
      padding-right: 20px;
    }

    .Tabs-Community p {
      font-size: 0.85rem;
      line-height: 34px;
    }
  }
`;
