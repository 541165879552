import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import cn from "classnames";

import { fetchHealthInfo } from "patient_app/api/onboardingActions";
import { fetchLicense, createDocument } from "patient_app/api/profileActions";
import {
  NEW_ERRORS,
  OPEN_CHAT,
  CLEAR_FIELD_SPECIFIC_ERRORS,
} from "patient_app/constants/actionTypes";

import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import DragAndDrop from "patient_app/components/utils/DragAndDrop";
import MainTabBack from "patient_app/views/profiles/partials/MainTabBack";

import assets from "patient_app/components/chat/assets";
import { mobileCheck } from "patient_app/helpers/supported";
import { getUrlVars } from "patient_app/helpers/linkHelpers";
import permissions from "patient_app/helpers/permissions";

const DriversLicense = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const [driversLicense, healthInfo, loading, success] = useSelector(
    (state) => {
      return [
        state.profile.driversLicense,
        state.onboarding.healthInfo,
        state.profile.loading,
        state.profile.success,
      ];
    }
  );

  const [files, setFiles] = useState(null);
  const profileId = params.id;

  const clearFieldSpecificErrors = () => {
    dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS });
  };

  const newGeneralErrors = (errors) => {
    dispatch({ type: NEW_ERRORS, errors: errors, errorsClass: "profile" });
  };

  useEffect(() => {
    document.title = "Driver's License | Workit Health";
    dispatch(fetchLicense(profileId));
    dispatch(fetchHealthInfo());
    clearFieldSpecificErrors();
    newGeneralErrors([]);
  }, []);

  useEffect(() => {
    if (files) {
      setFiles(null);

      if (healthInfo && !healthInfo.finished_checklist) {
        history.push("/onboarding/consent");
      }
    }
  }, [success]);

  const renderLicenseImages = () => {
    return driversLicense.map((img, i) => {
      const url = img.file.url;
      return (
        <a key={i} href={url} target="_blank" className="drivers-license">
          <img key={i} src={url} alt={`Driver's license, image ${i}`} />
        </a>
      );
    });
  };

  const handleChat = (e) => {
    e.preventDefault();
    const message =
      "Hi, I don't have a state issued driver's license. What should I do?";
    dispatch({
      type: OPEN_CHAT,
      data: {
        view: "index",
        prepopulatedMessage: message,
        topic: "ADMIN",
      },
    });
  };

  const handleFileInput = (file) => {
    if (!files) {
      setFiles([file]);
    } else if (files.length === 5) {
      newGeneralErrors([{ text: "Cannot upload more than 5 files." }]);
      window.scrollTo(0, 0);
      return;
    } else {
      // compare files
      let isDifferent = true;
      files.forEach((existingFile) => {
        if (
          existingFile.name === file.name &&
          existingFile.size === file.size
        ) {
          isDifferent = false;
        }
      });

      if (isDifferent) {
        setFiles([...files, file]);
      }
    }
  };

  const handleRemoveFile = (fileIndex) => {
    let updatedFiles = [...files];
    updatedFiles.splice(fileIndex, 1);
    setFiles(updatedFiles);
  };

  const handleSubmit = () => {
    if (!files) {
      newGeneralErrors([{ text: "Please select a file to upload." }]);
      window.scrollTo(0, 0);
      return;
    }

    let userAgent = mobileCheck() ? "mobile" : "comp";

    const types = ["png", "jpeg", "gif", "jpg", "heic", "heif", "bmp"];
    let errors = null;
    files.forEach((file) => {
      let anyValidType = false;
      if (types.map((type) => file.type.includes(type))) {
        anyValidType = true;
      }

      if (!anyValidType) {
        errors = [{ text: "Invalid file type." }];
      }
    });

    if (errors) {
      newGeneralErrors(errors);
      window.scrollTo(0, 0);
      return;
    }

    //2,000,000 bytes
    // if (file.size > 6000000) {
    //   newGeneralErrors([{ text: "Please choose a smaller file." }])
    //   window.scrollTo(0,0)
    //   return
    // }

    let formData = new FormData();
    files.forEach((file) => {
      formData.append("images[]", file);
    });
    // formData.append("images[]", files);
    formData.append("title", "Drivers License");

    dispatch(createDocument(profileId, formData));
  };

  return (
    <div>
      <MainTabBack />

      <div className="profile-section last">
        <h1>Driver's License</h1>

        <p>A photo ID is required to receive treatment.</p>

        {driversLicense && !loading && <div>{renderLicenseImages()}</div>}

        <DragAndDrop
          files={files}
          onFileInput={handleFileInput}
          onRemoveFile={handleRemoveFile}
          allowMultiple={true}
        />

        <button
          className={`custom-button submit ${loading ? "loading" : ""}`}
          onClick={handleSubmit}
        >
          Upload License
        </button>

        <p className="under-submit">
          Don't have a state issued license?{" "}
          <a href="#" onClick={handleChat}>
            Message your admin team.
          </a>
        </p>
      </div>
    </div>
  );
};

export default DriversLicense;
