import Api from "patient_app/api";

import {
  NEW_ERRORS,
  APPTS_LOAD,
  APPTS_ACTION_ITEMS,
  PENDING_ITEM_START,
  PENDING_ITEM_SUCCESS,
  PENDING_ITEM_FAILURE,
  PENDING_ITEM_LOAD,
  PENDING_ITEM_UPDATE_SIGNATURE,
  PENDING_ITEM_START_TALKING,
  APPTS_USER_RECOVERY_GROUP,
} from "patient_app/constants/actionTypes";

export const fetchPendingItem = (userId, title = null, itemId = null) => {
  // endpoint item_approval will fetch the last unapproved PendingItem;
  // if title is present, it will fetch the last unapproved item that has a
  // matching title
  let url = `/users/${userId}/item_approval?json=true`;
  if (title) {
    url += `&title=${title}`;
  }

  if (itemId) {
    // if a PendingItem id is passed in, use PendingItems#show instead
    url = `/api/v2/pending_items/${itemId}`;
  }

  const data = {
    url: url,
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: PENDING_ITEM_LOAD,
          item: res.item,
          clinicFile: res.clinic_file,
        });
        //dispatch({ type: PENDING_ITEM_UPDATE_SIGNATURE, signature: res.signature })
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const signPendingItem = (itemId, signature, fileUrl) => {
  const data = {
    url: "/registrations/approve_pending_item",
    path: "api/v2",
    data: {
      method: "POST",
      body: {
        id: itemId,
        signature: signature,
        file_url: fileUrl || null,
      },
    },
  };

  return async (dispatch) => {
    dispatch({ type: PENDING_ITEM_START });
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: PENDING_ITEM_SUCCESS });
      } else {
        dispatch({ type: PENDING_ITEM_FAILURE });

        if (res.payment_failed) {
          window.location.href = "/payments/purchase?error=payment_failed";
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const declinePendingItem = (itemId) => {
  const data = {
    url: `/pending_items/${itemId}?decline=true&json=true`,
    data: {
      method: "DELETE",
      body: {
        decline: true,
        json: true,
      },
    },
  };

  return async (dispatch) => {
    dispatch({ type: PENDING_ITEM_START });
    try {
      const res = await Api.makeRequest(data);
      if (res.success) dispatch({ type: PENDING_ITEM_SUCCESS });
      else dispatch({ type: PENDING_ITEM_FAILURE });
    } catch (e) {
      console.log(e);
    }
  };
};

export const createConsentToTreatment = (items, signature, successCallback) => {
  const data = {
    url: `/registrations/approve_consent_to_treatment`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: { items: items, signature: signature },
    },
  };

  return async (dispatch) => {
    dispatch({ type: PENDING_ITEM_START });
    try {
      const res = await Api.makeRequest(data);

      if (res.success) {
        dispatch({ type: PENDING_ITEM_SUCCESS });
        successCallback();
      } else {
        console.log(res, "not successful");
        dispatch({ type: PENDING_ITEM_FAILURE });

        if (res.payment_failed) {
          window.location.href = "/payments/purchase?error=payment_failed";
        } else {
          //if some other error occurs, let appointments dash handle redirect
          successCallback();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const deferConsent = () => {
  const data = {
    url: `/registrations/defer_consent`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: {},
    },
  };

  return async (dispatch) => {
    dispatch({ type: PENDING_ITEM_START });
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        window.location.href = "/appointments";
      } else {
        dispatch({ type: PENDING_ITEM_FAILURE });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchStartTalking = () => {
  const data = {
    url: `/start_talking/fetch`,
    data: {
      method: "GET",
    },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: PENDING_ITEM_START_TALKING, startTalking: res.form });
        return;
      }
      dispatch({
        type: NEW_ERRORS,
        errors: [
          {
            text: "Could not fetch Opioid Start Talking, please refresh the page and try again.",
          },
        ],
      });
    } catch (e) {
      console.log(e);
    }
  };
};

/*
  @params
  title (string): PendingItem title
  uid (string): PendingItem uid
  from (string): one of "checklist" or "todo"
*/
export const skipPendingItem = (title, uid) => {
  const data = {
    url: `/pending_items/skip_pending_item`,
    path: "/api/v2",
    data: {
      method: "POST",
      body: {
        title: title,
        uid: uid,
      },
    },
  };

  return async (dispatch) => {
    dispatch({ type: PENDING_ITEM_START });
    try {
      dispatch({ type: PENDING_ITEM_START });
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({ type: PENDING_ITEM_LOAD, item: res.pending_item });
        dispatch({ type: PENDING_ITEM_SUCCESS });
      } else {
        dispatch({ type: PENDING_ITEM_FAILURE });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

/*
  @params
  completed (boolean): if true, will fetch completed todos & past appointments
  userId (integer): User id, only required if viewing as admin
*/
export const fetchActionItems = (completed = false, userId = null) => {
  let url = `/pending_items/action_items`;
  if (completed) {
    url += `?completed=true`;
  }
  if (completed && userId) {
    url += `&user_id=${userId}`;
  }
  if (!completed && userId) {
    url += `?user_id=${userId}`;
  }
  const data = {
    url: url,
    path: "/api/v2",
    data: { method: "GET" },
  };

  return async (dispatch) => {
    try {
      const res = await Api.makeRequest(data);
      if (res.success) {
        dispatch({
          type: APPTS_LOAD,
          completed: completed,
          appointments: res.upcoming_appointments,
          completedAppointments: res.completed_appointments,
        });
        const items = completed ? res.completed_todos : res.upcoming_todos;
        const userRecoveryGroups = res.recovery_groups;
        dispatch({
          type: APPTS_ACTION_ITEMS,
          key: completed ? "completed" : "current",
          items: items,
          gtcId: res.gtc_id,
          mymId: res.mym_id,
        });
        dispatch({
          type: APPTS_ACTION_ITEMS,
          key: completed ? "completed" : "current",
          items: items,
          gtcId: res.gtc_id,
          mymId: res.mym_id,
        });
        dispatch({
          type: APPTS_USER_RECOVERY_GROUP,
          userRecoveryGroups: userRecoveryGroups,
        });
      } else {
        dispatch({ type: NEW_ERRORS, errors: res.errors });
      }
    } catch (e) {
      console.log(e);
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Could not fetch action items." }],
      });
    }
  };
};

export const uploadItemFiles = (itemId, formData) => {
  const data = {
    url: `/pending_items/${itemId}/upload_image`,
    path: "/api/v2",
    data: {
      method: "POST",
      formData: formData,
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: PENDING_ITEM_START });
      const res = await Api.makeRequestFile(data);
      if (res.success) {
        dispatch({
          type: PENDING_ITEM_LOAD,
          item: res.pending_item,
        });
        dispatch({ type: PENDING_ITEM_SUCCESS });
      } else {
        dispatch({ type: NEW_ERRORS, errors: [{ text: res.error }] });
        dispatch({ type: PENDING_ITEM_FAILURE });
      }
    } catch (e) {
      console.log(e);
      dispatch({
        type: NEW_ERRORS,
        errors: [{ text: "Could not fetch action items." }],
      });
    }
  };
};
